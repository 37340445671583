import React from 'react';
import CurriculumSelector from '../../components/CurriculumSelector';
import DigitalLessonBundle from '../../components/DigitalLessonBundle';
import { Column, Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';
import Video from '../../components/Video';
import Action from '../../components/Resource/Action';

const IndexPage = ({ data }) => (
  <Layout title="Forces of Motion">
    <Section>
      <Container>
        <Row>
          <Column size={11}>
            <h1 className="page-header">Forces and Motion</h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              poster="forces-of-motion-placeholder.png"
              guidSrc="b0fd32f9-dcb8-4de8-be69-ce4ae370e9d4"
              title="Forces and Motion"
            />
          </Column>
          <Column size={4}>
            <p>
              Inspire students to interact with their environment, investigate
              important challenges, and create original solutions by tapping
              into the universal laws of nature. Watch the topic series video to
              learn how students can harness the Laws of Motion to ignite
              innovation and pursue exciting STEM careers emerging in the
              Industry 4.0 revolution.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row className="educator-guide-forces">
          <Column size={4}>
            <Image filename="resources-image-EdGuide.png" />
          </Column>
          <Column size={6} className="educator-guide-column">
            <h3>Video Topic Series Guide</h3>
            <p>
              The Caterpillar Foundation Video Series Guide for high school
              physics is a standards-aligned curriculum of engaging videos and
              hands-on activities designed to bring the world of STEM and
              Industry 4.0 into the classroom. Students learn the fundamentals
              of physical laws and how to problem solve by tackling real-world
              challenges in class and after-school sessions. Through a process
              of investigation, team discussion, and hands-on experiences,
              students will advance theory into applications they can see and
              touch.
            </p>
          </Column>
          <Column size={2} className="bottom-button">
            <div className="resource__actions resource__actions--aligned">
              <Action
                label="Download"
                dropdown={true}
                dropdownItemClassname="vts__dropdown-items"
                actions={[
                  {
                    label: 'English',
                    download: '/pdfs/Caterpillar-VTS-EdGuide.pdf',
                    metadata: '.PDF, 410KB',
                  },
                  {
                    label: 'Spanish',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_ES.pdf',
                    metadata: '.PDF, 418KB',
                  },
                  {
                    label: 'French',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_FR.pdf',
                    metadata: '.PDF, 436KB',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <h2>Hands-On Classroom Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Discover how the Laws of Motion play a part in every single thing
              we do with these simple, easy-to-implement activations aligned to
              education standards. Students will take on a series of creative
              STEM challenges in which they put the power of force and motion
              into action.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              img={'resources-image-activity1.png'}
              subjects={['Classroom Activity']}
              title={'Chain Reactions'}
              audience="Are you aware of the chain reactions taking place in your life?"
              description="Explore the power of our decisions by investigating the cause and effect relationships involved in chain reactions. Students will consider the possibilities of automation before constructing a Rube Goldberg machine designed to improve everyday life."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-ChainReactions.pdf',
                      metadata: '.PDF, 495KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-ChainReactions_ES.pdf',
                      metadata: '.PDF, 498KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-ChainReactions_FR.pdf',
                      metadata: '.PDF, 513KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'resources-image-activity2.png'}
              subjects={['Classroom Activity']}
              title={'Goal!'}
              audience="Do all inventions make life easier?"
              description="Celebrate the human fascination with technology and innovation as students learn about simple and complex machines. Students will collaborate to create a machine capable of launching an object at a desired target."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download: '/pdfs/Caterpillar-TeacherActivity-Goal.pdf',
                      metadata: '.PDF, 750KB',
                    },
                    {
                      label: 'Spanish',
                      download: '/pdfs/Caterpillar-TeacherActivity-Goal_ES.pdf',
                      metadata: '.PDF, 699KB',
                    },
                    {
                      label: 'French',
                      download: '/pdfs/Caterpillar-TeacherActivity-Goal_FR.pdf',
                      metadata: '.PDF, 707KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'resources-image-activity3.png'}
              subjects={['Classroom Activity']}
              title={'Head Protection'}
              audience="Are helmets the most life-saving type of personal protective equipment?"
              description="Put students’ design skills to the test as they explore the laws of motion involved in creating the perfect piece of head protection. After investigating the impacts of head trauma, students will apply what they’ve learned in an egg drop challenge."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-HeadProtection.pdf',
                      metadata: '.PDF, 509KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-HeadProtection_ES.pdf',
                      metadata: '.PDF, 548KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-HeadProtection_FR.pdf',
                      metadata: '.PDF, 569KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** QUOTE **************/}
    <Section>
      <Container>
        <Row>
          <Column size="12" className="forces-quote">
            <Image
              filename="resources-graphic-quotemark.svg"
              className="start-slash"
            />
            <p className="forces-quote-text">
              AI, big data, machine learning, and automation will usher in a new
              and novel world full of interesting careers that don’t even exist
              today.
            </p>
            <Image
              filename="resources-graphic-quotemark.svg"
              className="end-slash"
            />
            <p className="forces-quote-author">– Kat, Host</p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** AFTERSCHOOL ACTIVITIES **************/}
    <Section>
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>After School Activity</h2>
          </Column>
          <Column size={6}>
            <Image filename="resources-graphic-lineart-ASactivity.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Explore the ways that the Laws of Motion can be harnessed in
              students’ everyday lives with a series of engaging activities that
              are easy to implement in any environment.
            </p>
          </Column>
        </Row>
        <Row>
          <DigitalLessonBundle
            resource1={
              <Resource
                img={'resources-image-ASactivity.png'}
                subjects={['Subject Area']}
                title={'Magnus Effect Extension Activity'}
                className="magnus-effect"
                description="Uncover the mysteries of the Magnus Effect as students learn how this unique phenomenon of motion is used to achieve amazing effects in sports, engineering and beyond. Kickstart creative problem-solving with a series of hands-on student activations that put the fundamentals of motion into action."
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/Caterpillar-AfterschoolExtension-ReadySetGoal.pdf',
                  },
                ]}
              />
            }
            resource2={
              <Resource
                img={'global-image-ASFacilitatorguide@2x.png'}
                title={'Facilitator Guide'}
                className="facilitator-guide"
                description="Students learn the fundamentals of physics and how to solve problems by tackling real-world challenges in out of class and after-school sessions. Through a process of investigation, team discussion, and hands-on experiences, students will advance theory into applications they can see and touch."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download: '/pdfs/Caterpillar-AfterschoolGuide.pdf',
                        metadata: '.PDF, 606KB',
                      },
                      {
                        label: 'Spanish',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-ES.pdf',
                        metadata: '.PDF, 718KB',
                      },
                      {
                        label: 'French',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-FR.pdf',
                        metadata: '.PDF, 736KB',
                      },
                    ],
                  },
                ]}
              />
            }
            className="dlb-row"
          />
        </Row>
      </Container>
    </Section>
    {/************** CURRICULUM SELECTOR **************/}
    <Section>
      <Container>
        <CurriculumSelector />
      </Container>
    </Section>
    {/************** TESTIMONIAL **************/}
    <Section>
      <Container>
        <Row>
          <Column>
            <Image filename="resources-image-testimonial.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
